import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import * as styles from './carousel.module.scss';
import VideoPopup from '@components/Fellowship/VideoPopup/index';

import 'swiper/css/bundle';
import './index.css';
import { useDialog } from '@components/v5/Dialog/index';

SwiperCore.use([Navigation, Autoplay]);

const youTubeIdFromLink = (url) =>
  url.match(
    /(?:https?:\/\/)?(?:www\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&]+)/,
  )[1];

function MobileCard(props) {
  const thumbnail = getImage(props.data.thumbnail);
  const companyLogo = getImage(props.data.companyLogo);
  const [openDialog, closeDialog] = useDialog();

  return (
    <div
      className="mx-auto mt-0 flex w-10/12 flex-col justify-between overflow-hidden rounded-md bg-crio-neutral-250 p-4"
      style={{ minHeight: '500px' }}
    >
      <div>
        <div className="relative mb-5 cursor-pointer overflow-hidden rounded-lg">
          <div
            className="iframe-image  rounded-lg"
            // onClick={() => {
            //   props.setVideo(props.data.iFrame);
            //   props.setOpen();
            // }}
            onClick={() => {
              openDialog({
                children: (
                  <VideoPopup
                    video={props.data.iFrame}
                    closeDialog={closeDialog}
                  ></VideoPopup>
                ),
              });
            }}
          >
            <FontAwesomeIcon
              className="inset-center absolute text-white transition-all hover:opacity-60"
              icon={['fas', 'play']}
              size="2x"
              style={{ zIndex: '99' }}
            />
            <GatsbyImage
              loading="lazy"
              image={thumbnail}
              alt={'youtube'}
              width={250}
              style={{ width: '100%', transform: 'scale(1.4)' }}
            />
            {/* <img
              src={`https://img.youtube.com/vi/${youTubeIdFromLink(
                props.data.iFrame,
              )}/hqdefault.jpg`}
              style={{ width: '100%', transform: 'scale(1.4)' }}
            ></img> */}
          </div>
        </div>
        <div>
          <div className="mb-3 flex items-start">
            <h5
              className={` font-semibold text-crio-green-900 ${styles.customFont}`}
            >
              Meet{' '}
              <span className="text-crio-green-600">{props.data.name}</span>
            </h5>
            <a href={props.data.linkedin} target="_blank" rel="noreferrer">
              <div className="ml-2 mt-1 h-5  w-5 rounded-full ">
                <StaticImage
                  loading="lazy"
                  src="../../../../images/Linkedin.png"
                  placeholder="blurred"
                  alt="Linkedin"
                  imgStyle={{ objectFit: 'contain' }}
                />
                {/* <Image
                imgStyle={{ objectFit: 'contain' }}
                filename="Linkedin.png"
              ></Image> */}
              </div>
            </a>
          </div>
          <p className="pb-10 text-left text-crio-neutral-800 md:text-sm ">
            {props.data.quote}
          </p>
        </div>
      </div>

      <div
        className="  mx-auto rounded-md  bg-crio-neutral-300 px-2 "
        // style={{ height: "calc(100% - 50px)" }}
      >
        <p
          className="pt-2 pb-0 font-bold text-crio-neutral-500"
          style={{ fontSize: '12px' }}
        >
          {props.data.name} cracked a career at
        </p>
        <div className="flex items-center justify-center ">
          <div
            style={{ maxHeight: '100%', height: '60px', width: '120px' }}
            className="flex items-center justify-center"
          >
            <GatsbyImage
              loading="lazy"
              image={companyLogo}
              alt={'company'}
              width={120}
              style={{ maxHeight: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function CarouselCodeSplit(props) {
  const mobile = props.isMobile;
  const settings = {
    slidesPerView: mobile ? 1 : 4,
    id: 'heroCarousel',
    loop: true,
    spaceBetween: 30,
    speed: 300,
    grabCursor: true,
    noSwiping: false,
    // pagination: {
    //   el: ".swiper-pagination",
    //   type: "bullets",
    // },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    // observer: true,
    // observeParents: true,
  };

  const [video, setVideo] = useState('');
  const [openVideo, setOpenVideo] = useState(false);

  return (
    <>
      {/* <VideoPopup
        isOpen={openVideo}
        video={video}
        setOpen={(val) => setOpenVideo(val)}
      ></VideoPopup> */}

      <Swiper
        {...settings}
        className="carousel-fellowship"
        pagination={{ clickable: true }}
        // modules={[Virtual]}
        // virtual
      >
        {props.data.map((val, idx) => {
          return (
            <SwiperSlide key={`heroSlide-${idx} pb-8`}>
              <MobileCard
                data={val}
                // setVideo={(video) => {
                //   setVideo(video);
                // }}
                // setOpen={() => {
                //   setOpenVideo(!openVideo);
                // }}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
